import React from 'react';
import { SVGIcon } from '../../globals';

interface SVGIconsProps {
    icon: string;
    width?: number;
    height?: number;
}

const SVGIcons: React.FC<SVGIconsProps> = (props: SVGIconsProps) => {
    
    switch(props.icon) {
        case SVGIcon.solidity:
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                viewBox="0 0 1000 1000"
                preserveAspectRatio="xMidYMid meet">
                
                <g transform="translate(-50.000000,1250.000000) scale(0.100000,-0.100000)" fill="#121212" stroke="none">
                    <path d="M5122 10458 c-5 -7 -301 -530 -657 -1163 -626 -1113 -646 -1151 -632
                        -1175 8 -14 299 -531 647 -1150 348 -619 636 -1129 640 -1133 4 -5 301 514
                        660 1152 l652 1161 1299 0 c715 0 1299 3 1299 8 0 4 -292 525 -648 1157 l-647
                        1150 -1301 3 c-1034 2 -1304 -1 -1312 -10z"/>
                    <path d="M7095 5815 l-649 -1155 -1304 0 c-1039 0 -1303 -3 -1299 -12 3 -7
                        295 -528 649 -1158 l644 -1145 1310 -2 1309 -2 648 1153 c356 634 647 1158
                        647 1165 0 15 -1291 2311 -1300 2311 -3 0 -298 -520 -655 -1155z"/>
                </g>
            </svg>;
        case SVGIcon.github:
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                fill="#333"
                viewBox="0 0 24 24"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>;
        case SVGIcon.twitter:
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                fill="#55ACEE"
                viewBox="0 0 24 24"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>;
        case SVGIcon.linkedin:
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                fill="#007bb5"
                viewBox="0 0 24 24"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>;
        case SVGIcon.instagram:
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                fill="#D42C74"
                viewBox="0 0 18 18"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>;
        case SVGIcon.facebook:
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                width={props.width ?? "24"}
                height={props.height ?? "24"}
                fill="#1861CD"
                viewBox="0 0 18 18"
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>;
    }
    return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "24"}
        height={props.height ?? "24"}
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet">
        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
    </svg>;
};

export default SVGIcons;