export enum Direction {
    horizontal = 'horizontal',
    vertical = 'vertical'
}

export enum Size {
    small = 'small',
    big = 'big'
}

export enum Accent {
    primary = 'primary',
    secondary = 'secondary',
    thirdary = 'thirdary',
    transparent = 'transparent'
}

export enum ButtonType {
    linkInternal = 'linkInternal',
    linkExternal = 'linkExternal',
    submit = 'submit',
    custom = 'custom'
}

export const Topicos = [
    {name: "Iniciante", value: 'iniciante'},
    {name: "Intermediário", value: 'intermediario'},
    {name: "Avançado", value: 'avancado'},
    {name: "Aplicações", value: 'aplicacoes'},
    {name: "Hacks", value: 'hacks'},
    {name: "DeFi", value: 'defi'},
    {name: "Artigos", value: 'artigos'}
];

export enum SVGIcon {
    twitter = 'twitter',
    github = 'github',
    facebook = 'facebook',
    instagram = 'instagram',
    youtube = 'youtube',
    solidity = 'solidity',
    remix = 'remix',
    linkedin = 'linkedin'
}

export default { Direction, Size, Accent, ButtonType, Topicos };
