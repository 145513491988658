import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment, useState } from 'react';
import { Accent, ButtonType, Size, Topicos } from '../../../globals';
import SVGIcons from '../../utils/SVGIcons';
import Button from '../Button/Button';
import './Header.scss';
import NavigationDrawer from './NavigationDrawer/NavigationDrawer';
import SearchBox from './SearchBox/SearchBox';

//..GatsbyImageSharpFluid_noBase64 for no blur up effect
const fetchLogoQuery = graphql`
    query {
        file(relativePath: { eq: "logos/icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 64, maxHeight: 64) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;

const Header: React.FC = () => {
    const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
    const image = useStaticQuery(fetchLogoQuery);

    const toggleNavDrawer = () => {
        isNavDrawerOpen ? setNavDrawerOpen(false) : setNavDrawerOpen(true);
    };

    return (
        <Fragment>
            <header className="header">
                <div
                    className="header__hamburger-icon"
                    onClick={() => toggleNavDrawer()}
                >
                    <svg width="20" height="15">
                        <rect fill="#f4a444" width="20" height="3"></rect>
                        <rect fill="#f4a444" y="6" width="20" height="3"></rect>
                        <rect
                            fill="#f4a444"
                            y="12"
                            width="20"
                            height="3"
                        ></rect>
                    </svg>
                </div>
                <div className="header__item">
                    <Link to="/">
                        <Img
                            fluid={image.file.childImageSharp.fluid}
                            alt="AI Summer"
                            className="logo"
                        ></Img>
                    </Link>
                </div>
                <nav className="header__item nav">
                    {/* <div className="nav__item">
                        <Link to="/sobre/">Sobre</Link>
                    </div> */}
                    <div className="nav__item">
                        <Link to="/sobre-solidity/">O que é Solidity?</Link>
                    </div>
                    <div className="nav__item">
                        <Link to="/sobre-web3/">O que é Web3?</Link>
                    </div>
                    <div className="nav__item">
                        <Link to="/sobre-blockchain/">O que é Blockchain?</Link>
                    </div>
                    <div className="nav__item dropdown">
                        <div className="nav__item__link">
                            <Link to="">Tópicos</Link>
                            <div className="nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                {Topicos.map((topico, i) => (
                                    <div className="nav-dropdown-content__item">
                                        <Link to={"/topics/"+topico.value+"/"} key={i}>{topico.name}</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>                   
                </nav>

                <div className="header__item header__item__secondary">
                    <a href="https://github.com/search?l=Solidity&q=solidity&type=Repositories" target="_blank">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#333"
                            viewBox="0 0 24 24"
                        >
                            {/* eslint-disable-next-line max-len - Github */}
                            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                        </svg>
                    </a>
                </div>
                <div className="header__item header__item__secondary">
                    <a href="https://twitter.com/solidity_lang" target="_blank">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#55ACEE"
                            viewBox="0 0 24 24"
                        >
                            {/* eslint-disable-next-line max-len - Twitter */}
                            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                    </a>
                </div>
                <div className="header__item header__item__secondary">
                    <a href="https://readthedocs.org/projects/solidity/" target="_blank">
                        <SVGIcons icon='solidity' />
                    </a>
                </div>
                <div className="header__item header__item__secondary">
                    <a href="https://remix.ethereum.org/" target="_blank">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="24" 
                            height="24" 
                            viewBox="0 0 400 400"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                <path d="M1915 3834 c-22 -3 -89 -14 -148 -25 -582 -108 -1076 -549 -1253
                                    -1119 l-27 -87 -81 -6 c-194 -14 -322 -93 -357 -221 -8 -27 -10 -95 -6 -201
                                    12 -320 68 -508 191 -642 93 -102 183 -133 393 -133 104 0 271 15 281 25 2 2
                                    -17 39 -41 82 -229 397 -246 881 -47 1297 130 273 379 523 653 656 198 95 374
                                    134 607 134 183 0 276 -14 433 -65 355 -115 667 -389 827 -725 199 -416 182
                                    -900 -47 -1297 -24 -43 -43 -80 -41 -82 10 -10 177 -25 281 -25 210 0 300 31
                                    393 133 123 134 179 322 191 642 4 106 2 174 -6 201 -35 128 -163 207 -357
                                    221 l-81 6 -27 87 c-177 570 -663 1004 -1252 1120 -106 21 -378 34 -479 24z"/>
                                <path d="M1830 3371 c-47 -11 -98 -24 -113 -30 l-29 -11 7 -120 c4 -66 4 -120
                                    0 -120 -4 0 -47 36 -96 81 l-88 80 -39 -20 c-36 -18 -202 -138 -202 -146 0 -1
                                    23 -51 50 -110 28 -59 50 -111 50 -116 0 -4 -52 10 -117 33 l-116 42 -27 -33
                                    c-35 -42 -120 -191 -120 -211 0 -8 41 -51 91 -94 64 -56 87 -81 78 -86 -8 -4
                                    -59 -11 -114 -14 -55 -4 -107 -9 -117 -12 -19 -6 -38 -100 -41 -213 l-2 -74
                                    117 -36 c64 -20 119 -39 123 -42 3 -4 -46 -35 -109 -70 l-115 -64 6 -35 c9
                                    -56 61 -210 91 -270 l27 -55 527 -177 528 -176 530 176 529 177 35 77 c36 76
                                    86 235 86 268 0 12 -35 37 -114 81 -63 35 -112 67 -108 71 4 3 61 23 126 43
                                    l119 37 -6 86 c-3 48 -11 112 -18 144 l-11 56 -98 7 c-53 4 -112 10 -130 14
                                    l-33 6 84 75 c46 40 90 79 97 85 10 9 6 23 -19 71 -42 80 -112 184 -124 184
                                    -5 0 -59 -16 -118 -36 -60 -20 -110 -34 -113 -32 -2 3 19 54 46 114 l51 109
                                    -38 33 c-56 48 -191 134 -202 130 -6 -1 -48 -38 -95 -80 -46 -43 -88 -78 -91
                                    -78 -4 0 -4 54 0 120 l7 120 -28 11 c-45 17 -214 50 -242 47 -22 -3 -32 -17
                                    -70 -105 -25 -57 -48 -103 -52 -103 -3 0 -27 46 -51 103 -44 100 -46 102 -80
                                    104 -18 1 -72 -6 -119 -16z"/>
                                <path d="M1542 853 l538 -538 535 535 c294 294 530 536 523 538 -7 1 -248 -76
                                    -535 -172 l-523 -174 -522 174 c-288 96 -530 174 -538 174 -8 0 227 -242 522
                                    -537z"/>
                            </g>
                        </svg>
                    </a>
                </div>

                <div className="header__item search-nav">
                    <SearchBox size={Size.small} />
                </div>
                <div className="header__item newsletter-nav">
                    <Button
                        accent={Accent.primary}
                        type={ButtonType.linkExternal}
                        onClickLink="https://news.google.com/search?q=solidity"
                        className="newsletter-nav__button"
                    >
                        Notícias
                    </Button>
                </div>
            </header>
            <NavigationDrawer
                isNavDrawerOpen={isNavDrawerOpen}
                onToggleNavDrawer={toggleNavDrawer}
            />
        </Fragment>
    );
};

export default Header;
